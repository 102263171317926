<section class="account-settings" [ngClass]="{ 'h-full': isLoading }">
  <ng-container *ngTemplateOutlet="isLoading ? templateLoading : userCard" />
</section>

<!--Loading. -->
<ng-template #templateLoading>
  <div class="flex w-full h-full items-center">
    <app-loading-indicator class="flex-grow" />
  </div>
</ng-template>

<!-- User card.-->
<ng-template #userCard>
  <mat-card class="w-full md:w-11/12 lg:w-4/5 mx-auto sm:mt-6 sm:h-auto p-6">
    <h3 class="pb-4 text-sky-500">Account Settings</h3>
    <form
      (ngSubmit)="updateSettings()"
      class="relative"
      [formGroup]="settingsForm"
    >
      <h4 class="pb-4 text-secondary-800">General Account Settings</h4>

      <app-user-form
        #userFormComponent
        formControlName="userForm"
        [accountCreate]="false"
        class="w-full"
      />

      <div class="w-full">
        <a
          tabindex="0"
          (click)="openTerms()"
          (keypress)="openTerms()"
          class="my-3 cursor-pointer font-normal text-sm text-sky-500 hover:underline"
          >View the terms & conditions</a
        >
      </div>

      <div class="mobile-save mt-4 flex justify-end">
        <button
          #btnSaveUser
          data-testid="save-changes"
          id="save-changes"
          type="submit"
          mat-raised-button
          class="font-semibold"
          [ngClass]="
            btnSaveUser.disabled
              ? 'bg-gray-600 opacity-75 text-secondary-500'
              : '!bg-sky-500 !text-white'
          "
          [disabled]="
            settingsForm.invalid ||
            !settingsForm.dirty ||
            !!userFormComponent?.isLoadingImageUser
          "
        >
          SAVE CHANGES
        </button>
      </div>
      <div
        class="mt-4 sm:mt-0 text-center overline-font uppercase pb-12 sm:pb-0 text-gray-400"
      >
        {{ appVersionNumber }}
      </div>
    </form>
  </mat-card>
</ng-template>
